.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.5s ease-in-out;
  color: white;
}

.Main_Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10vh;
  background-color: transparent;
  justify-content: space-between;
  padding: 0 5vw; /* Add padding to the sides for better spacing */

  align-items: center;
  transition: background-color 0.5s ease-in-out;
}

.Main_Container.scrolled {
  background-color: rgba(57, 48, 44, 0.6);
}

.navbar-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  gap: 2vw;
}

.navbar-list li {
  list-style: none;
}

li:hover {
  cursor: pointer;
}

.title {
  font-size: 80px;
}

.links {
  font-size: 25px;
  margin: 10px;
}

button.Links {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  font-size: 1.5vw;
}

.buttonWelcome {
  background-color: #74c973;
  color: black;
  border-radius: 1.8vh;
  height: 4vh;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2vw; /* Adjust padding */
}

a .buttonWelcome {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonWelcome:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.language-switcher {
  position: relative;
  display: inline-block;
  background-color: none;
}

.lang-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.dropdown-menu {
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  background-color: transparent;
  border: none;
}

.navbar-list.active {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0;
}

.navbar-list.active .lang-button {
  display: none;
}

@media (max-width: 1135px) {
  .title {
    font-size: 80px;
  }

  .links {
    font-size: 20px;
  }

  .navbar-list li {
    padding: 5px;
  }
}

@media (max-width: 1000px) {
  .title {
    font-size: 60px;
  }

  .links {
    font-size: 15px;
  }
  .navbar-list li {
    padding: 0;
  }
}

@media (max-width: 768px) {
  button.Links {
    display: none;
  }

  .Main_Container {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .navbar-list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: none;
  }

  .burger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .burger-menu div {
    width: 30px;
    height: 4px;
    background-color: white;
    margin: 5px;
    transition: transform 0.3s ease-in-out;
  }

  .Main_Container {
    background-color: rgba(57, 48, 44, 0.6);
  }

  .navbar-list.active {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 40px;
  }

  .links {
    font-size: 18px;
  }

  .burger-menu div {
    width: 25px;
    height: 3px;
  }
}
