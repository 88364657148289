/* General Styling */
html {
  scroll-behavior: smooth;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Smooth scrolling and animation for sections */
.HomeSection,
.AboutSection {
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
  margin: 0;
  padding: 0;
}

/* Homepage Styles */
.HomePage {
  height: 100vh;
}

/* Profile Image Container */
.ProfileImage {
  position: relative;
  height: 100vh; /* Full viewport height */
  width: 100%;
}

/* Profile Image Styling */
.ProfileImage img {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures image covers the container */
}

/* Profile Presentation (Text Over Image) */
.ProfilePresentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%; /* Vertically centers the content */
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Precisely centers both horizontally and vertically */
  text-align: center;
  z-index: 2;
  width: 100%;
  color: white;
  padding: 0 20px; /* Adds horizontal padding */
  box-sizing: border-box;
}

/* Text Styling for Profile */
.ProfileText {
  font-size: 4rem; /* Better for scaling */
  margin: 0.5rem 0;
}

.ProfileRole {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  font-weight: 300; /* Lighter weight for the role text */
}

/* Button Styling */
.ProfileButton {
  background-color: #00233b;
  border: none;
  color: white;
  border-radius: 1.8vh;
  height: 4vh;
  padding: 0 2vw; /* Adjust padding */
  font-size: 1rem; /* Make button text more readable */
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.ProfileButton:hover {
  background-color: #74c973;
}

.social_icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfileIconLink {
  color: white;
  font-size: 2rem;
  margin: 0 1rem;
  transition: color 0.3s ease;
}

.ProfileIconLink:hover {
  color: #00233b;
}

/* Responsive Design for Small Screens */
@media screen and (max-width: 768px) {
  .ProfileImage {
    height: 50vh; /* Reduce image height on small screens */
  }

  .ProfileImage img {
    height: 100%; /* Let the image scale naturally */
    width: 100%; /* Adjust image width to 80% of the container */
    object-fit: cover; /* Adjust the image fit to 'contain' */
  }

  .ProfileText {
    font-size: 6vw; /* Scales the profile name */
  }

  .ProfileRole {
    font-size: 4vw; /* Scales the role text */
  }

  .ProfileButton {
    width: 100%;
    font-size: 4vw; /* Scales button text for smaller screens */
    height: auto;
  }
}

/* Further adjustments for very small screens (portrait phones, etc.) */
@media screen and (max-width: 480px) {
  .ProfileImage {
    height: 40vh; /* Further reduce image height for smaller screens */
  }

  .ProfileImage img {
    width: 100%; /* Increase width on very small screens */
    height: 100%;
  }
}

.homeBottomContainer {
  background-color: #000000;
  height: 20vh;
  align-items: center;
}

.Welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  position: relative;
  left: 15%;
  top: 15%;
  z-index: 2;
  width: 25vw;
  text-align: justify;
  font-size: medium;
}
/* About Me Section */
.aboutme {
  height: 94vh;
}

.container_Top_about {
  background-color: #eddbb9;
  height: 47vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container_Middle_about {
  background-color: #2e707d;
  height: 47vh;
  display: flex;
  flex-direction: row;
}

.container_presentation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 25%;
  height: 100%;
  margin-left: 5vw;
  text-align: justify;
  word-break: break-word;
  white-space: normal;
}

.container_presentation h1 {
  color: black;
  font-size: 50px;
  word-break: break-word;
  font-size: 2.5rem;
}

.contact_linkedin {
  background-color: #fa5c30;
  border: none;
  border-radius: 2vh;
  width: 150%;
  height: 3vh;
  color: white;
  margin-top: 5px;
}

.contact_linkedin:hover {
  cursor: pointer;
  background-color: #cc3b13;
}

.experience {
  display: flex;
  flex-direction: column;
  background-color: #fab51a;
  width: 25%;
  height: 100%;
  position: relative;
  bottom: 80%;
  left: 60%;
  justify-content: space-evenly;
  z-index: 1;
  word-wrap: break-word;
  word-break: keep-all;
  white-space: normal;
  font-size: 0.9rem;
}

.experience h1 {
  font-size: 2.5rem;
  margin-left: 20px;
}

.education {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 25%;
  height: 100%;
  color: white;
  margin-right: 50vw;
}

.education h1 {
  font-size: 40px;
  margin-left: 20px;
  color: #fab51a;
}

.experience ul,
.education ul {
  list-style-type: none;
  padding: 0;
}

.experience li,
.education li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.education li:before {
  content: "\2726";
  color: #fab51a;
  margin-right: 10px;
}

.experience li:before {
  content: "\2726";
  color: #ffffff;
  margin-right: 10px;
}

.odlaMer {
  text-align: justify;
  margin-left: 1vw;
  margin-right: 1vw;
  word-break: keep-all;
}

@media (max-width: 1024px) {
  .container_presentation {
    width: 30%;
    margin-left: 3vw;
    word-break: keep-all;
  }

  .experience {
    left: 65%;
    font-size: 0.7rem;
  }

  .education {
    width: 30%;
    margin-right: 0;
  }

  .container_presentation h1,
  .experience h1,
  .education h1 {
    font-size: 30px;
  }

  .contact_linkedin {
    width: 100%;
    height: 4vh;
  }

  .positionedTextAboutMe {
    display: none;
  }
}

@media (max-width: 768px) {
  .container_Top_about,
  .container_Middle_about {
    flex-direction: column;
    height: auto;
  }

  .container_presentation,
  .education {
    width: 80%;
    margin: 20px auto;
    height: auto;
  }

  .experience,
  .education {
    width: 90%;
    margin: 10px auto; /* Centering */
    height: auto; /* Let height adjust dynamically */
    padding: 10px;
    position: static; /* Remove fixed positioning */
    font-size: 0.9rem;
  }

  .experience h1,
  .education h1 {
    font-size: 18px; /* Adjust font size for smaller screens */
    text-align: center;
    margin: 10px 0; /* Add some spacing */
  }

  .contact_linkedin {
    width: 100%;
    height: 5vh;
  }

  .positionedTextAboutMe {
    font-size: 10vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 480px) {
  .aboutme {
    height: auto;
  }
  .experience,
  .education {
    width: 90%;
    margin: 10px auto; /* Centering */
    height: auto; /* Let height adjust dynamically */
    padding: 10px;
    position: static; /* Remove fixed positioning */
  }

  .experience h1,
  .education h1 {
    font-size: 18px; /* Adjust font size for smaller screens */
    text-align: center;
    margin: 10px 0; /* Add some spacing */
  }

  .experience ul,
  .education ul {
    padding: 0;
    font-size: 14px;
    line-height: 1.4;
  }

  .experience li,
  .education li {
    margin-bottom: 8px;
  }

  .odlaMer {
    margin: 5px 2vw; /* Margin adjusted */
    font-size: 14px;
  }

  .positionedTextAboutMe {
    font-size: 5vw; /* Use `vw` for responsive scaling */
    left: 50%;
    transform: translateX(-50%); /* Keep the transform */
  }

  .contact_linkedin {
    width: 100%;
    height: 6vh; /* Adjust height for smaller screens */
  }
}

/* Section Projects */
#projects {
  background-color: #eddbb9; /* Fond clair */
  padding: 40px 0; /* Espacement vertical plus large pour aérer */
  display: flex;
  justify-content: center;
}

/* Conteneur principal du carrousel */
.projects_page {
  width: 90%; /* Largeur réduite du carrousel pour un rendu plus centré */
  max-width: 1200px; /* Largeur maximale du carrousel */
  height: auto; /* Hauteur de la section */
}

.projects-title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
}

.slick-track {
  display: flex !important;
  gap: 2rem;
}

/* Slide de chaque projet */
.project_slide {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px; /* Espacement entre les éléments */

  transition: transform 0.5s ease-in-out; /* Effet de transition fluide */
  overflow: hidden; /* Empêche les éléments de déborder du slide */
}

/* Image des projets */
.project-image {
  width: 100%;
  height: 300px; /* Hauteur fixe pour toutes les images */
  object-fit: cover; /* Pour garder les proportions et ne pas déformer l'image */
}

/* Contenu du projet (texte) */
.project-content {
  padding: 20px;
  width: 80%; /* Largeur du texte par rapport à l'image */
  transition: background-color 0.3s ease-in-out; /* Animation de fond au survol */
}

/* Titres des projets */
.project-content h3 {
  color: #000000;
  margin-bottom: 0.5rem;
  letter-spacing: 1px; /* Espacement entre les lettres pour un effet plus aéré */
}

/* Description des projets */
.project-content p {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 1rem;
  line-height: 1.6; /* Meilleur espacement pour une lecture plus agréable */
  max-height: 100px; /* Limite la hauteur du texte pour éviter un trop grand bloc */
  overflow: hidden; /* Cacher le texte débordant */
}

/* Bouton Learn More */
.project-link {
  transition: background-color 0.3s, transform 0.3s; /* Animation au survol */
  display: inline-block; /* Pour que le bouton ne prenne pas toute la largeur */
}

/* Effet au survol du bouton */
.project-link:hover {
  transform: scale(1.05); /* Agrandissement léger du bouton */
}

/* Pour une meilleure visibilité sur les petits écrans */
/* Pour une meilleure visibilité sur les petits écrans */
@media (max-width: 768px) {
  .projects_page {
    width: 100%; /* Prendre toute la largeur sur mobile */
    padding: 0 10px; /* Ajouter du padding pour ne pas être collé sur les bords */
  }

  .project-slide {
    width: 95%; /* Augmenter légèrement la largeur des slides sur mobile */
  }

  .project-image {
    height: 200px; /* Réduire la hauteur des images sur les petits écrans */
  }

  .project-content {
    width: 100%; /* Prendre toute la largeur sur mobile */
    padding: 15px; /* Réduire le padding pour plus d'espace */
  }

  .project-content h3 {
    font-size: 1.5rem; /* Réduire la taille du titre */
  }

  .project-content p {
    font-size: 0.9rem; /* Réduire la taille du texte */
    max-height: 80px; /* Réduire la hauteur maximale du texte */
  }

  .project-link {
    font-size: 0.9rem; /* Réduire la taille du bouton */
  }

  /* Ajustement de l'espacement entre les éléments */
  .slick-track {
    gap: 1.5rem; /* Réduire l'écart entre les projets */
  }

  /* Équilibrer l'espacement et la disposition */
  .project_slide {
    gap: 10px; /* Moins d'espace entre l'image et le texte */
  }
}

/* Pour les très petits écrans (mobile portrait) */
@media (max-width: 480px) {
  .project-image {
    height: 150px; /* Réduire encore la hauteur des images sur les plus petits écrans */
  }

  .project-content {
    width: auto; /* Prendre toute la largeur sur mobile */
  }

  .project-content h3 {
    font-size: 0.9rem; /* Réduire encore la taille du titre */
    overflow-wrap: break-word;
  }

  .project-content p {
    max-height: 70px; /* Réduire la hauteur maximale du texte */
    overflow-wrap: break-word;
  }
  .slick-track {
    gap: 1rem; /* Réduire l'écart entre les projets */
  }

  .projects_page {
    width: 95%;
  }
}

/* Responsive Design */
@media screen and (max-width: 1100px) {
  #imagecontainer {
    width: 100%;
    height: 50vh;
    position: static;
    margin-right: 10%;
  }

  .Welcome {
    position: static;
    text-align: justify;
    margin: 20px;
    width: 70%;
  }
}

@media screen and (max-width: 770px) {
  .HomePage {
    height: 100%;
  }
}

@media screen and (max-width: 480px) {
  .HomePage {
    height: 100%;
  }

  .homeTopContainer {
    height: 70vh;
  }

  #imagecontainer {
    height: 30vh;
    width: 100%;
  }

  .positionedText {
    font-size: 8vw;
  }

  .Welcome {
    position: static;
    text-align: justify;
    margin: 20px;
    width: 90%;
  }
}

/* Section Padding */
@media (max-width: 768px) {
  section {
    padding: 10px;
  }
}
