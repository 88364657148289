* {
  margin: 0;
  padding: 0;
}

body{
  background-color: black;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
